@font-face {
	font-family: 'PP Hatton';
	font-style: medium;
	font-weight: 400;
	src: url('/assets/fonts/PPHatton-Medium.woff') format('woff');
	src: url('/assets/fonts/PPHatton-Medium.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Radikal';
	font-style: light;
	font-weight: 200;
	src: url('/assets/fonts/radikal-light-webfont.woff') format('woff');
	src: url('/assets/fonts/radikal-light-webfont.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'Radikal';
	font-style: medium;
	font-weight: 400;
	src: url('/assets/fonts/radikal-medium-webfont.woff') format('woff');
	src: url('/assets/fonts/radikal-medium-webfont.woff2') format('woff2');
	font-display: swap;
}
