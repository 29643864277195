header {
	padding: 10px 0;

	@include media-breakpoint-up(md) {
		padding: 0;
	}

	a {
		transition: color 0.4s;
		text-transform: lowercase;
		&:focus,
		&:hover,
		&:visited {
			color: $secondary;
		}
	}
	.col {
		display: flex;
		align-items: center;
	}

	.menuPart {
	}
}
