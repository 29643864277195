// GLOBAL STYLES
// essentially anything
// ( html, body, h1 - h6, strong, em )
// -----------------------------------

// Test if gulp autoprefix works
#autoprefixer-test {
	display: grid;
	transition: all 0.5s;
	user-select: none;
	background: linear-gradient(to bottom, white, black);
}
html {
	background-color: $primary;
}
body {
	font-family: $fontPrimary;
	background-color: $primary;
	color: #222;
	position: relative;

	&.hideNav {
		#nav {
			opacity: 0 !important;
			/* visibility: hidden; */
		}
	}
}

h1,
h2,
h3,
h4 {
	margin-bottom: 0.6em;
	margin-top: 0.9em;
	line-height: 1.1em;
	color: $secondary;
	font-family: 'PP Hatton', sans-serif;
}

h1 {
	font-size: 4em;
	@include antialiased;
	font-size: calc(40px + 3vw);
}

h2 {
	font-size: 2.5em;
	font-size: calc(40px + 1.5vw);
	small {
		font-size: 30px;
		vertical-align: super;
	}
}

h3 {
	font-size: 2em;
}

h4 {
	font-size: 1.5em;
}

a {
	color: #fff;
	text-decoration: none;
	font-family: $fontPrimary;
	&:hover,
	&:focus,
	&:active,
	&:visited {
		color: #fff;
	}
}

p {
	margin-bottom: 2rem;
	letter-spacing: 0.01em;
	color: $secondary;
	font-weight: 200;
}

::selection {
	background-color: $secondary;
	color: $primary;
}

section {
	overflow: hidden;
}
/* side nav */
#nav {
	position: absolute;
	right: 0px;
	z-index: 999;
	border-radius: 0;
	justify-content: space-evenly;
	transition: all 0.4s;
	opacity: 1;
	visibility: visible;
	pointer-events: all;
	top: 50%;
	transform: translateY(-50%);
	@include media-breakpoint-up(md) {
		right: 5px;
		position: fixed;
	}
	@include media-breakpoint-down(sm) {
		display: none;
	}

	.list-group-item {
		background: transparent;
		span {
			border-color: transparent;
		}
		&.active {
			span {
				border-color: $textDark;
			}
		}
	}

	a {
		border: none;
		display: inline-block;
		padding: 0;

		display: flex;
		justify-content: flex-end;
		align-items: center;
		color: $textDark;

		font-size: 15px;
		letter-spacing: 0.05em;
		@include antialiased;
		@include rotateElement;
		white-space: nowrap;
		text-transform: lowercase;
		font-weight: 200;

		position: relative;
		&:not(:first-of-type) {
			&:after {
				content: '';
				display: inline-block;
				width: 1px;
				height: 50px;
				background-color: $textDark;
			}
		}

		@include media-breakpoint-up(md) {
			font-size: 18px;
		}
		span {
			position: relative;
			padding: 12px 4px;
			border: 1px solid $textDark;
			border-radius: 20px;
			margin: 5px 0;
			transition: border-color 0.4s;
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
	}
}

/* images */
.img,
picture.img {
	position: relative;
	overflow: hidden;
	@include ratio(60%);
	background-color: $placeholder;
	img {
		@include cover;
	}
}
picture {
	display: inline-block;
}
.lazy {
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}
.entered.loaded {
	opacity: 1;
	visibility: visible;
}

/* accordion remove styling */
.accordion-item,
.accordion-button {
	border-radius: 0;
	background-color: transparent;
	border: none;
	color: $secondary;
	box-shadow: none;
}
.accordion-button:focus,
.accordion-button:not(.collapsed) {
	background-color: transparent;
	border: none;
	color: $secondary;
	box-shadow: none;
}
.accordion-button:after {
	display: none;
}
.accordion-header {
	margin-top: 0;
}
.accordion-item:first-of-type {
	border-radius: 0;
}
