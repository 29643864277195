.h__medium {
	font-size: flexible-font(70, 117);
	@include media-breakpoint-down(sm) {
		font-size: 48px;
	}
}
.h__small {
	font-size: 77px;
	font-size: flexible-font(50, 97);
	@include media-breakpoint-down(sm) {
		font-size: 44px;
	}
}
.p__medium {
	font-size: 28px;
	font-size: flexible-font(20, 30);
	line-height: 121%;
	@include antialiased;
	@include media-breakpoint-down(sm) {
		font-size: 18px;
	}
}
.p__small {
	font-size: 24px;
	font-size: flexible-font(19, 30);
	line-height: 151%;
	@include antialiased;
	font-weight: 200;
}
