.btn-primary {
	color: #fff;
	background-color: $textDark;
	border-radius: 60px;
	font-weight: 200;
	@include font-size(26px);
	letter-spacing: 0.01em;
	@include antialiased;
	line-height: 1;
	vertical-align: middle;
	padding: 16px 48px 18px 48px;
	text-transform: lowercase;
	transition: background-color 0.2s;
	&:hover {
		background-color: darken($textDark, 2%);
		&:after {
			left: 5px;
		}
	}
	&:after {
		content: '';
		display: inline-block;
		width: 31px;
		height: 19px;
		margin-left: 30px;
		background: url(/img/arrow-white.svg) center no-repeat;
		background-size: contain;
		position: relative;
		top: 3px;
		left: 0;
		transition: left 0.2s;
	}
}
.accordion__button {
	color: $textDark;
	border: 1px solid $textDark;
	font-size: 17px;
	letter-spacing: 0.01em;
	position: relative;
	background-color: transparent;
	font-family: $fontPrimary;
	border-radius: 21px;
	padding: 9px 16px;
	line-height: 1;
	transition: all 0.2s;
	white-space: nowrap;
	@include media-breakpoint-up(md) {
		font-size: 20px;
		padding: 9px 21px;
	}

	&:after {
		content: '';
		transform: scaleY(1);
		transition: transform 0.2s;
		display: inline-block;
		width: 15px;
		height: 10px;
		background: url(/img/arrow-down.svg) center no-repeat;
		background-size: contain;
		margin-left: 14px;
	}
}
