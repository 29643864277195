#pageHome {
	/* SPLIT-TYPE */
	.line {
		display: block;
		overflow: hidden;
	}
	.line .lineInner {
		line-height: 1;
		position: relative;
	}

	.overflowNormal,
	.overflowNormal .line {
		overflow: visible;
	}

	/* MARQUEE */
	.marqueeWrap {
		overflow: hidden;
	}

	.marquee {
		font-family: 'PP Hatton', sans-serif;
		position: relative;
		left: -100vw;
		@include font-size(122px);
		white-space: nowrap;
		-webkit-text-stroke: 1.5px rgba($color: $secondary, $alpha: 1);
		color: rgba(0, 34, 98, 0);
		@include antialiased;
		@include media-breakpoint-down(md) {
			-webkit-text-stroke: 1px rgba($color: $secondary, $alpha: 1);
		}
		svg {
			margin: 0 40px;

			.fill {
				fill: rgba(0, 34, 98, 0);
			}
			@include media-breakpoint-down(sm) {
				transform: scale(0.8);
				margin: 0;
			}
		}
	}

	section.top {
		position: relative;

		/* GSAP Flicker fix */
		visibility: hidden;
		opacity: 0;
		.topWrap {
			margin-bottom: 6em;
			@include media-breakpoint-up(md) {
				padding: 30px 0 0 90px;
			}
		}
		.scrollWrap {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100vh;
			pointer-events: none;
			z-index: 1;
		}

		.leftPart {
			display: inline-flex;
			flex-direction: column;
			justify-content: center;

			.center__wrap {
				@media only screen and (min-width: 1600px) {
					margin: 0 auto;
				}
			}
			@include media-breakpoint-down(md) {
				padding-top: 4em;
				padding-bottom: 4em;
				align-items: center;
				text-align: center;
			}
			h1 {
				line-height: 111%;
				margin: 0;
				margin-bottom: 0.9em;
				font-size: flexible-font(70, 210);
        @include media-breakpoint-up(md) {
          margin-bottom: 0.5em;
        }
				small {
					display: block !important;
					margin-bottom: 0.3em;
					line-height: 1;
					font-size: flexible-font(50, 130);
          
				}
			}
			.wrap {
				display: flex;
				justify-content: center;
        margin-top: 0.2em;
        @include media-breakpoint-up(md) {
        justify-content: flex-start;
        

        }
				.desc {
					line-height: 121%;
					font-size: flexible-font(25, 35);
					@include antialiased;
          font-family: "Radikal", serif;
          font-weight: 200;
          display: block;
					width: 60%;
					@include media-breakpoint-up(md) {
						padding-left: 30px;
					}
				}
				svg {
					@include media-breakpoint-down(md) {
						transform: scale(0.8);
						position: relative;
						top: -10px;
					}
				}
			}
			a {
				width: max-content;
			}
		}
		.rightPart {
			.img {
				position: relative;
				overflow: hidden;
				width: 88%;
				@include ratio(140%);
				border-radius: 300px;
				transform: rotate(6deg);
				max-width: 670px;
				@media only screen and (min-width: 1600px) {
					display: block;
					margin: 0 auto;
				}
				@include media-breakpoint-down(md) {
					display: block;
					margin: 0 auto;
				}
				img {
					@include cover;
				}
			}
		}
	}

	section.about {
		margin: 6em 0 6em 0;
		max-width: 1600px;
		@media only screen and (min-width: 1600px) {
			margin: 6em auto 3em auto;
		}
		.audio {
			.topRow {
				h2 {
					line-height: 121%;
					span.icon {
						@include ir;
						display: inline-block;
						width: 114px;
						height: 60px;
						transform: rotate(5deg);
						margin-top: 10px;
						background: url(../../img/wave.svg) center no-repeat;
						background-size: contain;
						@include media-breakpoint-down(md) {
							transform: scale(0.8);
							margin-top: 0px;
						}
					}
				}
				p {
					line-height: 151%;
				}
			}

			#custom__player {
				width: 100%;
				display: block;
				max-width: 900px;

				background-color: $textDark;
				border-radius: 110px;
				color: #fff;
				margin: 1em auto 3em auto;
				@include media-breakpoint-up(md) {
					margin: 4em auto 4em auto;
				}

				.audio__wrap {
					display: flex;
					@include media-breakpoint-up(md) {
						padding: 40px 50px 40px 50px;
					}
				}
				button {
					background-color: white;
					width: 90px;
					height: 90px;
					border-radius: 50%;
					outline: none;
					box-shadow: none;
					border: none;
					@include media-breakpoint-down(md) {
						transform: scale(0.8);
					}

					svg {
						position: relative;
						left: 3px;
					}
				}
				.audio__left {
					display: flex;
					justify-content: center;
					align-items: center;
				}
				.audio__right {
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-left: 20px;
					margin-right: 20px;
					@include media-breakpoint-up(md) {
						margin-left: 80px;
						margin-right: 80px;
					}

					h3,
					p {
						margin: 0;

						color: white;
						font-family: $fontPrimary;
						@include antialiased;
					}
					h3 {
						font-size: 18px;
						margin-bottom: 0.4em;
						@include media-breakpoint-up(md) {
							font-size: 23px;
						}
					}
					p {
						font-size: 19px;
						@include media-breakpoint-down(md) {
							display: none;
						}
					}
					.audio__bottom {
						display: flex;
						justify-content: space-between;
						margin-top: 0.5em;
						@include media-breakpoint-down(md) {
							display: none;
						}

						div {
							@include antialiased;
							font-weight: 200;
						}
					}
				}
			}
		}

		.aboutText {
			margin: 8em 0;

			@include media-breakpoint-up(md) {
				padding: 0 70px 0 70px;
			}
			.text__wrap {
				margin-top: -6em;
				@include media-breakpoint-up(md) {
					padding-top: 2em;
					margin-top: 0;
				}
				h2 {
					@include media-breakpoint-down(md) {
						text-align: center;
					}
				}
				p {
					line-height: 157%;
					@include antialiased;
					text-align: center;
					@include media-breakpoint-up(md) {
						padding-left: 50px;
						text-align: left;
					}
				}
			}

			picture {
				position: relative;
				display: block;
				overflow: hidden;
				margin-left: auto;
				margin-right: 0;
				background-color: $placeholder;

				&.fst {
					width: 68%;
					@include ratio(140%);
					border-radius: 200px;
					transform: rotate(4.7deg);
				}
				&.snd {
					width: 45%;
					@include ratio(140%);
					border-radius: 200px;
					transform: rotate(-15deg);
					top: -110px;
					left: -200px;
				}
				img {
					@include cover;
				}
			}
		}
	}

	section.reference {
		margin: -12em 0 4em 0;
		@include media-breakpoint-up(md) {
			margin: -12em 0 10em 0;
		}

		.row.topRow {
			margin-bottom: 2em;
			@media only screen and (min-width: 1820px) {
				max-width: 1820px;
				margin: 0em auto 4em auto;
			}
			@include media-breakpoint-up(md) {
				margin-bottom: 4em;
			}

			h2 {
				@include media-breakpoint-down(md) {
					white-space: nowrap;
				}
				span.icon {
					@include ir;
					display: inline-block;
					width: 122px;
					height: 84px;
					transform: rotate(-14deg);
					margin-left: 10px;
					background: url(../../img/megafon.svg) center no-repeat;
					background-size: contain;
					@include media-breakpoint-down(md) {
						margin: 0;
						position: relative;
						top: 10px;
						left: -5px;
						transform: scale(0.7) rotate(-14deg);
					}
				}
			}
		}
		.accordion {
			max-width: 1300px;
			@media only screen and (min-width: 1300px) {
				margin: 1em auto 2em auto;
			}
			@include media-breakpoint-up(md) {
				margin: 1em 0 2em 0;
			}
			.accordion-item {
				border-top: 1px solid $textDark;

				&:hover {
					.accordion__button {
						color: white;
						background-color: $textDark;
						&:after {
							background: url(/img/arrow-down-white.svg) center no-repeat;
							background-size: contain;
						}
					}
				}
				&.opened {
					.accordion-button .accordion__button:after {
						transform: scaleY(-1);
					}
				}
				h3 {
					padding: 0;
					button {
						font-family: $fontPrimary;
						font-weight: 200;
						@include antialiased;
						font-size: 18px;
						display: flex;
						justify-content: space-between;
						align-items: baseline;
						@include media-breakpoint-up(md) {
							font-size: 24px;
						}
						.accordion__year {
							font-family: $fontDecoration;
							padding-right: 15px;
							@include media-breakpoint-up(md) {
								padding-right: 75px;
								width: 160px;
								display: inline-block;
							}
							@include media-breakpoint-down(md) {
								display: block;
							}
						}
					}
				}
			}

			.accordion-body {
				padding: 1em 0 40px 1em;
				&.p__small {
					font-size: 18px;
					@include media-breakpoint-up(md) {
						font-size: 20px;
					}
				}
				a {
					color: $textDark;
					text-decoration: underline;
					text-decoration-thickness: 0.5px;
					text-underline-offset: 4px;
				}
			}
		}

		.brands {
			min-height: 100px;
			margin: 4em 0;
			@include media-breakpoint-up(md) {
				margin: 8em 0;
			}
			.wrap {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
				a {
					margin: 0 50px;
					@include media-breakpoint-down(md) {
						margin: 0 20px;
						svg {
							transform: scale(0.8);
						}
					}
				}
			}
		}

		.row.video {
			margin: 10em 0 0 0;

			max-width: 1600px;
			@media only screen and (min-width: 1600px) {
				margin: 14em auto 0em auto;
			}
			@include media-breakpoint-up(md) {
				margin: 14em 0 0 0;
				padding: 0 50px 0 30px;
			}
			.leftPart {
				display: flex;
				flex-direction: column;
				justify-content: center;
				h2 {
					font-size: 64px;
					font-size: flexible-font(40, 86);

					@include media-breakpoint-up(md) {
						margin-bottom: 2em;
					}
					small {
						font-family: $fontPrimary;
						opacity: 0.7;
						font-size: 30px;
						font-weight: 200;
						@include antialiased;
						display: block !important;
					}
					.line:first-of-type {
						margin-bottom: 0.2em;
					}
				}
				p {
					font-size: 23.3965px;
					line-height: 121.3%;
					display: block;
					width: 50%;

					margin-bottom: 2em;
					@include media-breakpoint-up(md) {
						margin-bottom: 0;
						margin-left: 2em;
					}
				}
			}
			.video {
				position: relative;
				overflow: hidden;
				@include ratio(66%);
				border-radius: 45px;
				background-color: $placeholder;
				@include media-breakpoint-up(md) {
					border-radius: 75px;
				}

				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	section.instagram {
		margin: 4em 0 0em 0;

		@include media-breakpoint-up(md) {
			padding-left: 30px;
			margin: 16em 0 0em 0;
		}
		@media only screen and (min-width: 1600px) {
			margin: 16em 0em 0em auto;
		}

		h2 {
			line-height: 121%;
			@include media-breakpoint-down(md) {
				text-align: center;
			}
			a {
				text-decoration: none;
				color: $secondary;
				font-family: $fontDecoration;

				@include multilineUnder(#fff, 100%, 3px);
				background-position: bottom 7px left;
				transition: background-size 0.2s;
				@include media-breakpoint-up(md) {
					@include multilineUnder(#fff, 100%, 6px);
				}
				&:hover {
					background-size: 0% 3px;
					@include media-breakpoint-up(md) {
						background-size: 0% 6px;
					}
				}
			}
		}
		.images__wrap {
			display: inline-flex;
			@include media-breakpoint-down(md) {
				display: flex;
				justify-content: center;
			}
			a.img {
				display: block;
				position: relative;
				overflow: hidden;
				width: 24vw;
				@include ratio(100%);
				margin: 0 5px;
				border-radius: 40px;
				max-width: 430px;
				min-width: 200px;
				@include media-breakpoint-up(md) {
					margin: 0 10px;
				}
				img {
					@include cover;
					transition: all 0.4s;
					transform: scale(1);
				}
				&:hover {
					img {
						transform: scale(1.05);
					}
				}
			}
		}
	}

	section.contact {
		padding-top: 5em;
		margin-bottom: 3em;
		max-width: 1600px;
		@include media-breakpoint-up(md) {
			padding-top: 10em;
			margin-bottom: 3em;
		}
		@media only screen and (min-width: 1600px) {
			margin: 0em auto 18em auto;
		}

		.rowTop {
			margin-bottom: 2em;
			@include media-breakpoint-up(md) {
				margin-bottom: 4em;
			}
			h2 {
				position: relative;
				span:not(.icon) {
					display: block;
				}
				span.icon {
					@include ir;
					display: inline-block;
					width: 120px;
					height: 70px;
					position: relative;
					left: 20px;
					background: url(../../img/wavelength.svg) center no-repeat;
					background-size: contain;
					@include media-breakpoint-down(md) {
						transform: scale(0.8);
						left: 0;
					}
				}
			}
		}
		.rowBottom {
			.rowBottom_wrap {
				@include media-breakpoint-up(md) {
					padding: 0 31px;
					display: flex;
					justify-content: stretch;
				}
				.contact__wrap {
					border-top: 1px solid $secondary;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;
					padding: 20px 0 30px 0;
					flex: 1;
					@include media-breakpoint-up(md) {
						padding: 40px 0 40px 0;
					}
					&:first-of-type {
						@include media-breakpoint-up(md) {
							border-right: 1px solid $secondary;
						}
					}
					span,
					a {
						display: block;
						color: $secondary;
					}
					span {
						font-weight: 200;
						@include antialiased;
						margin-bottom: 0.5em;
						font-size: 22px;
            opacity: 0.8;
						@include media-breakpoint-up(md) {
							font-size: 25px;
						}
					}
					a {
						text-decoration: none;
						font-family: $fontDecoration;
						font-size: 28px;
						white-space: nowrap;
						@include multilineUnder(#fff, 100%, 3px);
						background-position: bottom 7px left;
						transition: background-size 0.2s;
						@include media-breakpoint-up(md) {
							font-size: 38px;
						}
						&:hover {
							background-size: 0% 3px;
						}
					}
				}
			}
		}
    .rowIco{
      margin-top: 6em;
      @include media-breakpoint-up(md) {
      margin-top: 10em;}
      p{
        opacity: 0.7;
      }
    }
	}
}
