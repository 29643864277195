$primary: #e9e9e9; // primary brand color
$secondary: #002262;
$textLight: #fffef1;
$textDark: #001949;
$bonus: #e4e5df;
$g: #4affff; // guide lines
$placeholder: #b3b3b3; // image placeholder
$formColor: #0f413c;

$fontPrimary: 'Radikal', serif;
$fontDecoration: 'PP Hatton', serif;
